<template>
	<div class="social-links" v-if="linksList">
		<app-link
			v-for="(url, name) in linksList"
			:key="name"
			:to="url"
			:style="getStyles(name)"
			class="link"
		>
			<div></div>
		</app-link>
	</div>
</template>

<script>
  import AppLink from "../../AppLink";
  import {mapState} from "vuex";
  export default {
    name: "SocialButtons",
    components: {AppLink},
    computed:{
      ...mapState({
        settings: state => state.channel.settings,
      }),
      linksList(){
        return Object.fromEntries(
		            Object.entries(this.settings['social-links'] || {})
			            .filter(([_, url]) => url)
		        )
      }
    },
    methods:{
      getStyles(name){
        return this.$options.LINKS[name]?.styles || this.$options.DEFAULT_STYLES
      }
    },
    DEFAULT_STYLES: {
      '--bcg': 'linear-gradient(5deg, rgb(39, 61, 187), rgb(39, 61, 187) 50%, rgb(0, 61, 255) 50%, rgb(0, 61, 255) 100%)',
      '--mask':'url("/image/globe.svg")'
    },
    LINKS: {
      'instagram-link': {
        name: 'insta',
        styles: {
          '--bcg': 'radial-gradient(at 0% 80%, red, blue)',
          '--mask':'url("/image/insta.svg")'
        }
      },
      'tiktok-link': {
        name: 'tiktok',
        styles: {
          '--bcg': 'linear-gradient(45deg, rgb(242, 0, 75), rgb(0, 234, 227))',
          '--mask':'url("/image/tiktok.svg")'
        }
      },
      'twitter-link': {
        name: 'twitter',
        styles: {
          '--bcg': 'rgb(62, 186, 222)',
          '--mask':'url("/image/twitter.svg")'

        }
      },
      'facebook-link': {
        name: 'facebook',
        styles: {
          '--bcg': 'rgb(62, 62, 203)',
          '--mask':'url("/image/facebook.svg")'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
	@import "src/assets/css/colors";
	.social-links{
		display: flex;
		gap: 1.5rem;
	}
	.link{
		width: 2rem;
		height: 2rem;
		color: $text-secondary;
		display: block;
		position: relative;
		svg{
			width: 100%;
			height: 100%;
		}

		mask-position: center;
		-webkit-mask-position: center;
		mask-size: contain;
		-webkit-mask-size: contain;
		mask-repeat: no-repeat;
		-webkit-mask-repeat: no-repeat;
		background: $text-secondary;
		&:before{
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: var(--bcg);
			clip-path: circle(0rem at 0% 100%);
			transition: .3s linear;
		}
		&:hover{
			&:before{
				clip-path: circle(5rem at 0% 100%);
			}
		}

		mask-image: var(--mask);
		-webkit-mask-image: var(--mask);
	}
</style>